import {Component, Input, OnChanges, OnInit, signal, SimpleChanges, WritableSignal} from '@angular/core';
import {ICommandPaletteGroup} from '../../interfaces';
import Fuse, {FuseResult} from 'fuse.js';
import {IAnyObject} from '@shared/interfaces';
import {NgIf} from '@angular/common';

@Component({
  selector: 'shortcut-tab-command-palette',
  template: `
    <p class="flex items-center gap-2 truncate">
      <span class="text-xxs text-gray-400" *ngIf="nearestOption">Buscar {{ nearestOption }}</span>
      <span class="text-xxs text-gray-400" *ngIf="!nearestOption">Cerrar</span>
      <span class="text-xxs text-gray-700 py-1 px-1.5 rounded bg-gray-100 font-semibold">{{ nearestOption ? 'TAB' : 'ESC' }}</span>
    </p>
  `,
  styles: [],
  imports: [
    NgIf,
  ],
  standalone: true,
})
export class ShortcutTagCommandPaletteComponent implements OnInit, OnChanges {
  @Input() value: string = '';
  @Input() groupsTab: WritableSignal<ICommandPaletteGroup[]> = signal<ICommandPaletteGroup[]>([]);
  nearestOption: string | null = null;
  fuse?: Fuse<ICommandPaletteGroup>;
  fuseOptions: IAnyObject = {
    keys: ['title'],
    includeScore: true,
    threshold: 0.4
  };

  ngOnInit(): void {
    this.fuse = new Fuse(this.groupsTab(), this.fuseOptions);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      if (this.value !== '') {
        const fuseValues: FuseResult<ICommandPaletteGroup>[] | undefined = this.fuse?.search(this.value);
        if (fuseValues && (fuseValues ?? [])?.length > 0) this.nearestOption = fuseValues[0].item.title;
      } else {
        this.nearestOption = null;
      }
    }
  }
}
