import {Component, EventEmitter, Input, OnChanges, Output, signal, SimpleChanges, WritableSignal} from '@angular/core';
import {NgIf, NgStyle} from '@angular/common';
import {ICommandPaletteGroup, ICommandPaletteGroupItem} from '../../interfaces';
import {Store} from '@ngxs/store';
import {fadeAnimation} from '@core/animations/fade.animation';
import {SearchKeywordContext} from '../../classes/search-keyword-strategy/search-keyword-context.class';
import {SearchKeywordEvent} from '../../classes/search-keyword-strategy/search-keyword-event.class';
import {SearchKeywordOrganization} from '../../classes/search-keyword-strategy/search-keyword-organization.class';
import {ISearchKeywordResultItem} from './search-keyword-result-item.interface';
import {UtilityClass} from '@shared/utils/utility.class';
import {
  SearchKeywordConnectionRequest
} from '../../classes/search-keyword-strategy/search-keyword-connection-request.class';
import {SearchKeywordOpportunity} from '../../classes/search-keyword-strategy/search-keyword-opportunity.class';
import {SearchKeywordProduct} from '../../classes/search-keyword-strategy/search-keyword-product.class';
import {SearchKeywordService} from '../../classes/search-keyword-strategy/search-keyword-service.class';
import {NzMessageService} from 'ng-zorro-antd/message';

@Component({
  selector: 'keyword-command-palette',
  template: `
    <span @fadeAnimation 
          *ngIf="this.groupSelected" 
          class="tag-keyword"
          [ngStyle]="{'background-color': groupSelected!.color, 'box-shadow': shadow}">
      {{ groupSelected!.title }}
    </span>
  `,
  styles: [
    `
      .tag-keyword {
        @apply px-2 py-1 mx-1.5 flex justify-center items-center bg-[#ff0000] rounded-full text-white text-xs transition capitalize;
      }
    `
  ],
  standalone: true,
  animations: [fadeAnimation],
  imports: [
    NgIf,
    NgStyle,
  ],
})
export class KeywordCommandPaletteComponent implements OnChanges {
  searchKeywordContext?: SearchKeywordContext;
  timeout: any;
  shadow: string = '';
  constructor(private store: Store, private nzMessageService: NzMessageService) {
    this.searchKeywordContext = new SearchKeywordContext(store);
  }
  @Input() groupSelected?: ICommandPaletteGroup;
  @Input() searchValue: string = '';
  @Output() valuesChange: EventEmitter<ICommandPaletteGroup[]> = new EventEmitter<ICommandPaletteGroup[]>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['groupSelected']) {
      this.setTagColor();
      this.setGroupSelected();
    }
    if (changes['searchValue'] && this.groupSelected) {
      this.search();
    }
  }

  setGroupSelected(): void {
    if (this.groupSelected?.title.toLowerCase().includes('eventos')) {
      this.searchKeywordContext?.setKeyword(new SearchKeywordEvent());
    } else if (this.groupSelected?.title.toLowerCase().includes('organización')) {
      this.searchKeywordContext?.setKeyword(new SearchKeywordOrganization());
    } else if (this.groupSelected?.title.toLowerCase().includes('conexiones')) {
      this.searchKeywordContext?.setKeyword(new SearchKeywordConnectionRequest());
    } else if (this.groupSelected?.title.toLowerCase().includes('oportunidades')) {
      this.searchKeywordContext?.setKeyword(new SearchKeywordOpportunity());
    } else if (this.groupSelected?.title.toLowerCase().includes('productos')) {
      this.searchKeywordContext?.setKeyword(new SearchKeywordProduct(this.nzMessageService));
    } else if (this.groupSelected?.title.toLowerCase().includes('servicios')) {
      this.searchKeywordContext?.setKeyword(new SearchKeywordService(this.nzMessageService));
    }
  }

  search(): void {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async (): Promise<void> => {
      await this.searchKeywordContext?.search(this.searchValue);
      const values: ISearchKeywordResultItem[] | undefined = await this.searchKeywordContext?.getValues();
      if (!values) return;
      const group: ICommandPaletteGroup = {
        title: 'Resultados', iconClass: this.groupSelected?.iconClass!, color: UtilityClass.stringToColor(this.groupSelected?.title!),
        items: values.map((item: ISearchKeywordResultItem): ICommandPaletteGroupItem => ({id: item.id, title: item.title, subtitle: item.subtitle, iconClass: 'las la-eye', route: item.route, action: item.action, metadata: item.metadata}))
      };
      this.valuesChange.emit([group]);
    }, 350);
  }

  setTagColor(): void {
    if (!this.groupSelected) return;
    const color: string = UtilityClass.stringToBrightColor(this.groupSelected?.title!);
    this.shadow = `0 0 8px ${UtilityClass.hexToRgba(color, 0.58)}`;
    this.groupSelected!.color = color;
  }
}
