import {Store} from '@ngxs/store';
import {firstValueFrom} from 'rxjs';
import {SearchKeyword} from './search-keyword.class';
import {OrganizationActions, OrganizationState} from '@organization/state';
import {ISearchKeywordResultItem} from '../../components/keyword-command-palette/search-keyword-result-item.interface';
import {IOrganizationWithRelations} from '@organization/interfaces';
import {PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {OrganizationTypeEnum} from '@organization/enums';
import {IConnectionRequestBasicInformation, IConnectionRequestWithRelations} from '@connection-request/interfaces';
import {ConnectionRequestActions, ConnectionRequestState} from '@connection-request/state/connection-request.state';
import {CONNECTION_TYPE_VALUES} from '@shared/config';

export class SearchKeywordConnectionRequest implements SearchKeyword {
  _store!: Store;
  constructor() {}

  setStore(store: Store) {
    this._store = store;
  }
  async search(value: string): Promise<void> {
    const filter: PrismaFilter<IConnectionRequestWithRelations> = {
      take: 10,
      where: {
        OR: [
          {title: {contains: value, mode: 'insensitive'}},
          {description: {contains: value, mode: 'insensitive'}},
          {
            HostOrganization: {
              tradeName: {contains: value, mode: 'insensitive'}
            }
          },
          {
            InvitedOrganization: {
              tradeName: {contains: value, mode: 'insensitive'}
            }
          },
          {
            Event: {
              OR: [
                {
                  Sector: {
                    name: {contains: value, mode: 'insensitive'}
                  },
                },
                {title: {contains: value, mode: 'insensitive'}}
              ]
            }
          }
        ]
      }
    }
    await firstValueFrom(this._store.dispatch(new ConnectionRequestActions.GetListBasicInformation(filter)));
  }

  async getValues(): Promise<ISearchKeywordResultItem[]> {
    const connections: IConnectionRequestBasicInformation[] | undefined = await firstValueFrom(this._store.selectOnce(ConnectionRequestState.getConnectionRequestListBasicInformation));
    return connections.map((connection: IConnectionRequestBasicInformation): ISearchKeywordResultItem => ({id: connection.id!, title: `${connection.hostOrganizationTradeName} - ${connection.invitedOrganizationTradeName}`, subtitle: CONNECTION_TYPE_VALUES[connection.connectionType], route: `connections/${connection.id}`, metadata: {item: connection}}));
  }
}
