import {LoaderState} from '@loader/state/loader.state';
import {CatalogState} from '@platform/state/catalog.state';
import {AuthState} from '@auth/state/auth.state';
import {PlatformState} from '@shared/state/platform.state';
import {UserState} from '@user/state';
import {OrganizationState} from '@organization/state/organization.state';
import {RoleState} from '@role/state/role.state';
import {AdminUserState} from '@admin-user/state/admin-user.state';
import {
  OrganizationCertificationState,
  OrganizationMemberState,
  OrganizationSupplierNumberState,
} from '@organization/state';
import {AddressState} from '@address/state/address.state';
import {PhoneState} from '@phone/state/phone.state';
import {OrganizationContactState} from '@organization/state/organization-contact.state';
import {ProductState} from '@product/state/product.state';
import {SATState} from '@shared/state/sat.state';
import {ServiceState} from '@service/state/service.state';
import {EventState} from '@event/state/event.state';
import {DocumentState} from '@document/state/document.state';
import {OpportunityState} from '@opportunity/state/opportunity.state';
import {GlobalConfigState} from './features/global/state/global-config.state';
import {ConnectionRequestState} from '@connection-request/state/connection-request.state';
import {CommandPaletteState} from './features/command-palette/state/command-palette.state';
import {StatisticsState} from '@shared/state/statistics.state';
export type StateClass<T = any> = new (...args: any[]) => T;

export const NGX_STATES: StateClass[] = [
  PlatformState,
  LoaderState,
  CatalogState,
  AuthState,
  UserState,
  OrganizationState,
  OrganizationMemberState,
  RoleState,
  PhoneState,
  AdminUserState,
  OrganizationCertificationState,
  AddressState,
  OrganizationContactState,
  ProductState,
  ServiceState,
  SATState,
  EventState,
  DocumentState,
  OpportunityState,
  OrganizationSupplierNumberState,
  ConnectionRequestState,
  GlobalConfigState,
  CommandPaletteState,
  StatisticsState,
]
