import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {inject} from '@angular/core';
import {Store} from '@ngxs/store';
import {AuthActions, AuthState} from '@auth/state';
import {IUserWithRelations} from '@user/interfaces';
import {firstValueFrom} from 'rxjs';
import {StorageService} from '@shared/services';
import {NzMessageService} from 'ng-zorro-antd/message';
import {UserActions} from '@user/state';
import {StorageItemEnum} from '@shared/services/storage/enums/storage-item.enum';

export const checkIsAdminGuard: CanActivateFn = async(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  const store: Store = inject(Store);
  const router: Router = inject(Router);
  const storageService: StorageService = inject(StorageService);
  const nzMessageService: NzMessageService = inject(NzMessageService);

  try {
    await firstValueFrom(store.dispatch(new UserActions.Me()));
    const usr: IUserWithRelations | null = store.selectSnapshot(AuthState.userAuthenticated);

    if (!usr || !usr.isAdmin) {
      storageService.clear();
      nzMessageService.info('Acceso denegado');
      await router.navigate(['/auth/login']);
      return false;
    }
    return true;
  } catch (err) {
    storageService.clear();
    storageService.set<string>(StorageItemEnum.RETURN_TO_URL, state.url);
    await router.navigate(['/auth/login']);
    return false;
  }
}
