import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';

export interface ICommandPaletteStore {
    show: boolean;
}


export namespace CommandPaletteActions {
    export class Show {
        static readonly type: string = '[CommandPalette] Show';
    }

    export class Hide {
        static readonly type: string = '[CommandPalette] Hide';
    }
}

@State<ICommandPaletteStore>({
    name: 'CommandPalette',
    defaults: {
        show: false
    }
})
@Injectable()
export class CommandPaletteState {

    constructor() {}


    @Selector()
    public static getVisibility({show}: ICommandPaletteStore): boolean {
        return show;
    }

    @Action(CommandPaletteActions.Show)
    show({patchState}: StateContext<ICommandPaletteStore>) {
        patchState({show: true});
    }


    @Action(CommandPaletteActions.Hide)
    hide({patchState}: StateContext<ICommandPaletteStore>) {
        patchState({show: false});
    }

}
