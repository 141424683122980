import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AnimationEvent} from '@angular/animations';
import {Select, Store} from '@ngxs/store';
import {LoaderState} from '@loader/state/loader.state';
import {Observable} from 'rxjs';
import {ContainerRotate, IconRotate, LogoRotate} from '@loader/animations';

export type MainLoaderStateType = 'before-load' | 'loading' | 'after-load'

export interface IMainLoaderItem {
  icon: string;
}

@Component({
  selector: 'wz-loader',
  template: `
    <div class="fixed w-full h-screen bg-white/60 z-[1000000] flex justify-center items-center" *ngIf="this.show">

      <div class="relative w-[150px] h-[150px]">

        <div class="absolute inset-0 flex justify-center items-center" *ngIf="this.show" [@logo]="{value: this.state}">
          <img class="w-16" [src]="'assets/images/logo/w.png'" alt="Logo"> <!--w-16-->
        </div>

        <div class="absolute inset-0" (@rotate.done)="done($event)" *ngIf="this.show" [@rotate]="{value: this.state}">
          <div *ngFor="let item of this.items; index as index;" class="absolute inset-0"
               [ngStyle]="{transform: 'rotate(' + (index * (360 / this.items.length)) +'deg)'}">
            <div class="inline-block"
                 [@icon]="{value: this.state, params: {rotate: -(index * (360 / this.items.length)), rotateEnd: -(index * (360 / this.items.length)) - 360}}">
              <img width="25" height="25" [src]="'assets/images/loader-icons/' + item.icon" alt="img">
            </div>
          </div>
        </div>

      </div>
    </div>
  `,
  standalone: true,
  imports: [
    CommonModule,
  ],
  animations: [
    LogoRotate,
    IconRotate,
    ContainerRotate,
  ]
})
export class WzLoaderComponent {
  @Select(LoaderState.active) active$! :Observable<boolean>;
  show: boolean = false;
  state: MainLoaderStateType = 'before-load';

  items: IMainLoaderItem[] = [
    {icon: 'chart.png'},
    {icon: 'woman.png'},
    {icon: 'admin.png'},
    {icon: 'supplier.png'},
    {icon: 'buyer.png'},
    {icon: 'user-tag.png'},
    {icon: 'event.png'},
    {icon: 'configuration.png'},
  ];

  constructor(
    private store: Store,
  ) {
    this.store.select(LoaderState.active).subscribe((active: boolean): void => {
      if (active) {
        this.show = true;
        this.state = 'before-load';
        return;
      }

      this.show = false;
    });
  }

  done(animationEvent: AnimationEvent): void {
    if (animationEvent.toState === 'before-load') {
      this.state = 'loading';
    }

    if (animationEvent.toState === 'loading') {
      this.state = 'after-load';
    }

    if (animationEvent.toState === 'after-load') {
      this.state = 'before-load';
    }
  }
}
