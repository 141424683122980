import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardTemplate } from '@shared/templates/dashboard/dashboard.template';
import {hasCredentialsGuard, setOrganizationTypeGuard} from '@shared/guards';
import {checkIsAdminGuard} from '@shared/guards/check-is-admin.guard';

const dashboardRoutes: Routes = [
  { path: 'home', loadChildren: () => import('@dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'config', loadChildren: () => import('@platform/platform.module').then(m => m.PlatformModule) },
  { path: 'opportunities', loadChildren: () => import('@opportunity/opportunity.module').then(m => m.OpportunityModule)},
  { path: 'connections', loadChildren: () => import('@connection-request/connection-request.module').then(m => m.ConnectionRequestModule)},
  { path: 'events', loadChildren: () => import('@event/event.module').then(m => m.EventModule) },
  { path: 'profile', loadChildren: () => import('@profile/profile.module').then(m => m.ProfileModule) },
  { path: 'platform', loadChildren: () => import('@platform/platform.module').then(m => m.PlatformModule) },
  { path: 'roles', loadChildren: () => import('@role/role.module').then(m => m.RoleModule) },
  { path: 'admin-users', loadChildren: () => import('@admin-user/admin-user.module').then(m => m.AdminUserModule) },
  { path: 'users', loadChildren: () => import('@user/user.module').then(m => m.UserModule) },
  {
    path: 'offers',
    children: [
      { path: 'products', loadChildren: () => import('@product/product.module').then(m => m.ProductModule) },
      { path: 'services', loadChildren: () => import('@service/service.module').then(m => m.ServiceModule) },
      { path: '**', redirectTo: 'products' },
    ]
  },
  { path: 'organizations', loadChildren: () => import('@organization/organization.module').then(m => m.OrganizationModule), canActivateChild: [setOrganizationTypeGuard] },
  { path: '**', redirectTo: 'profile', pathMatch: 'full'}
];

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('@auth/auth.module').then(m => m.AuthModule) },
  { path: 'display', loadChildren: () => import('@screen-mode/screen-mode.module').then(m => m.ScreenModeModule), canActivate: [checkIsAdminGuard, hasCredentialsGuard] },
  { path: '', children: dashboardRoutes, component: DashboardTemplate, canActivate: [checkIsAdminGuard, hasCredentialsGuard]},
  { path: '**', redirectTo: 'auth' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always', bindToComponentInputs: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
