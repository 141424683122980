import {Store} from '@ngxs/store';
import {firstValueFrom} from 'rxjs';
import {EventActions, EventState} from '@event/state/event.state';
import {SearchKeyword} from './search-keyword.class';
import {ISearchKeywordResultItem} from '../../components/keyword-command-palette/search-keyword-result-item.interface';
import {IEventBasicInformation, IEventWithRelations} from '@event/interfaces';
import {PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';

export class SearchKeywordEvent implements SearchKeyword {
  _store!: Store;
  constructor() {}

  setStore(store: Store) {
    this._store = store;
  }
  async search(value: string): Promise<void> {
    const filter: PrismaFilter<IEventWithRelations> = {
      take: 10,
      where: {
        OR: [
          {title: {contains: value, mode: 'insensitive'}},
          {
            Sector: {
              name: {contains: value, mode: 'insensitive'}
            }
          }
        ]
      }
    }
    await firstValueFrom(this._store.dispatch(new EventActions.FindBasicInformation(filter)));
  }

  async getValues(): Promise<ISearchKeywordResultItem[]> {
    const events: IEventBasicInformation[] | undefined = await firstValueFrom(this._store.selectOnce(EventState.getEventsBasicInformation));
    return events.map((event: IEventBasicInformation): ISearchKeywordResultItem => ({id: event.id!, title: event.title, subtitle: event.sectorName!, route: `/events/${event.id}`, metadata: {item: event}}));
  }
}
