import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {inject} from '@angular/core';
import {OrganizationService} from '@organization/services/organization.service';
import {IOrganizationWithRelations} from '@organization/interfaces';
import {OrganizationTypeEnum} from '@organization/enums';

export const setOrganizationTypeGuard: CanActivateFn = async(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  const router: Router = inject(Router);
  const id: string = route.params['id'];
  try {
    if (id && id !== 'new' && !route.queryParams['type']) {
      const urlTree = router.parseUrl(state.url);
      const organizationService: OrganizationService = inject(OrganizationService);
      const organization: IOrganizationWithRelations = await organizationService.getById(id);
      urlTree.queryParams = {...urlTree.queryParams, type: [OrganizationTypeEnum.BUYER, OrganizationTypeEnum.BOTH].includes(organization.organizationType) ? OrganizationTypeEnum.BUYER : OrganizationTypeEnum.SUPPLIER};
      await router.navigateByUrl(urlTree.toString());
      return true;
    }
    return true;
  } catch (err) {
    await router.navigate(['/', 'organizations']);
    return false;
  }
}
