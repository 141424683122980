import {Store} from '@ngxs/store';
import {firstValueFrom} from 'rxjs';
import {SearchKeyword} from './search-keyword.class';
import {ISearchKeywordResultItem} from '../../components/keyword-command-palette/search-keyword-result-item.interface';
import {PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {IProductBasicInformation, IProductWithRelations} from '@product/interfaces';
import {ProductActions, ProductState} from '@product/state/product.state';
import {UtilityClass} from '@shared/utils/utility.class';
import {NzMessageService} from 'ng-zorro-antd/message';

export class SearchKeywordProduct implements SearchKeyword {
  _store!: Store;

  constructor(private nzMessageService: NzMessageService) {
  }

  setStore(store: Store) {
    this._store = store;
  }

  async search(value: string): Promise<void> {
    const filter: PrismaFilter<IProductWithRelations> = {
      take: 10,
      where: {
        OR: [
          {name: {contains: value, mode: 'insensitive'}},
          {shortDescription: {contains: value, mode: 'insensitive'}},
          {
            ProductSubCategory: {
              name: {contains: value, mode: 'insensitive'},
              ProductCategory: {
                name: {contains: value, mode: 'insensitive'},
              },
            },
          },
        ],
      },
    }
    await firstValueFrom(this._store.dispatch(new ProductActions.FindBasicInformation(filter)));
  }

  async getValues(): Promise<ISearchKeywordResultItem[]> {
    const products: IProductBasicInformation[] | undefined = await firstValueFrom(this._store.selectOnce(ProductState.getProductsBasicInformation));
    return products.map((product: IProductBasicInformation): ISearchKeywordResultItem =>
      ({
        id: product.id!,
        title: product.name,
        subtitle: product.categoryName,
        action: async (): Promise<void> => {
          await UtilityClass.copyToClipboard(product.organizationTradeName);
          this.nzMessageService.success('Nombre comercial de la empresa copiado');
        },
        metadata: {item: product},
      }),
    );
  }
}
