import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {AuthActions} from '@auth/state/auth.state';
import {firstValueFrom} from 'rxjs';
import {CatalogActions} from '@platform/state/catalog.state';
import {UserActions} from '@user/state';
import {RoleActions} from '@role/state/role.state';
import {SATActions} from '@shared/state/sat.state';

export interface IPlatformState {
}

export namespace PlatformActions {
  export class Reset {
    static readonly type: string = '[Platform] Reset';
    constructor() {}
  }
}

const _DEFAULT_DATA: IPlatformState = {
};

@State<IPlatformState>({
  name: 'PlatformState',
  defaults: {..._DEFAULT_DATA},
})
@Injectable()
export class PlatformState {
  constructor(
  ) { }

  @Action(PlatformActions.Reset)
  async reset({dispatch}: StateContext<IPlatformState>): Promise<void>  {
    await firstValueFrom(dispatch([
      new CatalogActions.Reset(),
      new AuthActions.Reset(),
      new UserActions.Reset(),
      new RoleActions.Reset(),
      new SATActions.Reset()
    ]));
  }
}
