<div class="menu-header md:!h-0 transition-all duration-300 overflow-hidden">
  <div class="header-logo">
    <!--<img src="https://static-cse.canva.com/_next/static/assets/logo_w2000xh641_3b021976d60d0277e95febf805ad9fe8c7d6d54f86969ec03b83299084b7cb93.png" alt="">-->
    <img class="w-complete h-full object-contain w-[80%]" src="./assets/images/logo/concamin-horizontal-color.png" alt="">

  </div>

  <div class="flex items-center gap-2" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menuProfileOptions" [(nzVisible)]="nzVisible">

    <nz-avatar [nzSize]="40" class="min-w-[40px] uppercase"
               [nzSrc]="(this.userAuthenticated$ | async)?.Media | firstUrlFromMedia"
               [nzText]="(this.userAuthenticated$ | async)?.name | initialLetter"
    ></nz-avatar>

    <div class="flex flex-col items-center justify-center gap-0.5">
      <i class="las la-angle-up text-xs font-bold"></i>
      <i class="las la-angle-down text-xs font-bold"></i>
    </div>
  </div>
</div>


<!-- dropdown menu account-information options -->
<nz-dropdown-menu #menuProfileOptions="nzDropdownMenu">
  <ul nz-menu class="p-2 bg-white">
    <li nz-menu-item class="flex gap-2 items-center" routerLink="/profile/information">
      <div>
        <p class="text-gray-800 w-44" nz-typography nzEllipsis *ngIf="(this.userAuthenticated$ | async) as user">{{ user.name }} {{ user.lastName }}</p>
        <p class="text-xs text-gray-500">Mi perfil</p>
      </div>
    </li>
    <li nz-menu-divider></li>
    <button nz-button nzType="primary" nzDanger class="flex gap-2 items-center mt-2 text-center justify-center w-full"  (click)="this.onLogout()">
      <i class="las la-sign-out-alt text-base"></i>
      Cerrar sesión
    </button>
  </ul>
</nz-dropdown-menu>
