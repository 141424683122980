import {Store} from '@ngxs/store';
import {firstValueFrom} from 'rxjs';
import {SearchKeyword} from './search-keyword.class';
import {ISearchKeywordResultItem} from '../../components/keyword-command-palette/search-keyword-result-item.interface';
import {PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {IServiceBasicInformation, IServiceWithRelations} from '../../../service/interfaces';
import {ServiceActions, ServiceState} from '../../../service/state/service.state';
import {UtilityClass} from '@shared/utils/utility.class';
import {NzMessageService} from 'ng-zorro-antd/message';

export class SearchKeywordService implements SearchKeyword {
  _store!: Store;

  constructor(private nzMessageService: NzMessageService) {
  }

  setStore(store: Store) {
    this._store = store;
  }

  async search(value: string): Promise<void> {
    const filter: PrismaFilter<IServiceWithRelations> = {
      take: 10,
      where: {
        OR: [
          {name: {contains: value, mode: 'insensitive'}},
          {shortDescription: {contains: value, mode: 'insensitive'}},
          {
            ServiceSubCategory: {
              name: {contains: value, mode: 'insensitive'},
              ServiceCategory: {
                name: {contains: value, mode: 'insensitive'},
              },
            },
          },
        ],
      },
    }
    await firstValueFrom(this._store.dispatch(new ServiceActions.FindBasicInformation(filter)));
  }

  async getValues(): Promise<ISearchKeywordResultItem[]> {
    const services: IServiceBasicInformation[] | undefined = await firstValueFrom(this._store.selectOnce(ServiceState.getListBasicInformation));
    return services.map((service: IServiceBasicInformation): ISearchKeywordResultItem =>
      ({
        id: service.id!,
        title: service.name,
        subtitle: service.categoryName,
        action: async (): Promise<void> => {
          await UtilityClass.copyToClipboard(service.organizationTradeName!);
          this.nzMessageService.success('Nombre comercial de la empresa copiado');
        },
        metadata: {item: service},
      }),
    );
  }
}
