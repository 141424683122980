import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '@shared/services';

export const hasCredentialsGuard: CanActivateFn = async(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  const [authService, router] = [inject(AuthService), inject(Router)];


  const hasCredentials: boolean = await authService.hasCredentials();
  if (hasCredentials) return true;

  await router.navigate(['/', 'auth', 'login']);
  return false;
};
