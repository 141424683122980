import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from './app/app.module';


Sentry.init({
  dsn: "https://45ca1b8796e8d7525d7235af940a326a@o4506661099667456.ingest.sentry.io/4506661169528832",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({maskAllText: true, blockAllMedia: false}),
  ],
  tracePropagationTargets: ["localhost", /^https:\/\/(bi-stg\.plataformabi\.wizerg\.dev|server\.bizgto\.com)/],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
