import {Store} from '@ngxs/store';
import {firstValueFrom} from 'rxjs';
import {SearchKeyword} from './search-keyword.class';
import {ISearchKeywordResultItem} from '../../components/keyword-command-palette/search-keyword-result-item.interface';
import {PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {IOpportunityBasicInformation, IOpportunityWithRelations} from '@opportunity/interfaces';
import {OpportunityActions, OpportunityState} from '@opportunity/state/opportunity.state';
import {OPPORTUNITY_TYPE_VALUES} from '@shared/config/opportunity-type-values.const';

export class SearchKeywordOpportunity implements SearchKeyword {
  _store!: Store;
  constructor() {}

  setStore(store: Store) {
    this._store = store;
  }
  async search(value: string): Promise<void> {
    const filter: PrismaFilter<IOpportunityWithRelations> = {
      take: 10,
      where: {
        OR: [
          {name: {contains: value, mode: 'insensitive'}},
          {shortDescription: {contains: value, mode: 'insensitive'}},
        ]
      }
    }
    await firstValueFrom(this._store.dispatch(new OpportunityActions.GetAllListBasicInformation(filter)));
  }

  async getValues(): Promise<ISearchKeywordResultItem[]> {
    const opportunities: IOpportunityBasicInformation[] | undefined = await firstValueFrom(this._store.selectOnce(OpportunityState.getOpportunitiesBasicInformation));
    return opportunities.map((opportunity: IOpportunityBasicInformation): ISearchKeywordResultItem => ({id: opportunity.id!, title: opportunity.name, subtitle: OPPORTUNITY_TYPE_VALUES[opportunity.type], route: `/opportunities/${opportunity.id}`, metadata: {item: opportunity}}));
  }
}
