{
  "name": "adm-panel",
  "version": "1.3.9",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.2",
    "@angular/cdk": "^18.1.0",
    "@angular/common": "^18.2.2",
    "@angular/compiler": "^18.2.2",
    "@angular/core": "^18.2.2",
    "@angular/forms": "^18.2.2",
    "@angular/platform-browser": "^18.2.2",
    "@angular/platform-browser-dynamic": "^18.2.2",
    "@angular/router": "^18.2.2",
    "@ngxs/store": "^18.1.0",
    "@sentry/angular-ivy": "^7.114.0",
    "@swimlane/ngx-charts": "^20.5.0",
    "angular-calendar": "^0.31.1",
    "date-fns": "^2.30.0",
    "fuse.js": "^7.0.0",
    "gsap": "^3.12.5",
    "lodash": "^4.17.21",
    "masonry-layout": "^4.2.2",
    "ng-zorro-antd": "^18.1.0",
    "ng2-pdf-viewer": "^10.3.0",
    "ngx-drag-drop": "^17.0.0",
    "ngx-mask": "^16.4.2",
    "ngx-masonry": "^14.0.1",
    "ngx-print": "^1.5.1",
    "ngx-quill": "^24.0.5",
    "quill-mention": "4.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.6.3",
    "uuid": "^9.0.1",
    "webfontloader": "^1.6.28",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.2",
    "@angular/cli": "~18.2.2",
    "@angular/compiler-cli": "^18.2.2",
    "@ngxs/devtools-plugin": "^18.1.0",
    "@types/d3": "^7.4.3",
    "@types/jasmine": "~4.3.0",
    "@types/lodash": "^4.17.5",
    "@types/quill": "1.3",
    "@types/uuid": "^9.0.8",
    "@types/webfontloader": "^1.6.38",
    "autoprefixer": "^10.4.19",
    "jasmine-core": "~4.6.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "less": "^4.2.0",
    "less-plugin-npm-import": "^2.1.0",
    "postcss": "^8.4.38",
    "tailwindcss": "^3.4.4",
    "typescript": "^5.5.4"
  }
}
