<section class="dashboard">
  <header>
    <header-menu></header-menu>
  </header>
  <nav>
    <main-menu></main-menu>
  </nav>
  <main class="overflow-hidden">
    <router-outlet></router-outlet>
  </main>
  <footer></footer>
</section>
