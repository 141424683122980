import {Component, Input} from '@angular/core';
import {UploadModule} from '@shared/components/ui-elements/upload/upload.module';
import {DatePipe, JsonPipe, NgForOf, NgIf} from '@angular/common';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {AddHTTPSPipe, GetInitialLetterPipe} from '@shared/pipes';
import {EVENT_TYPE_VALUES, PLATFORM_CONFIG} from '@shared/config';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {IEventBasicInformation} from '@event/interfaces';

@Component({
  selector: 'command-palette-event',
  template: `
    <div class="p-4 border-l border-gray-100 rounded w-[300px] h-full">
      <div class="banner w-full overflow-hidden rounded h-[120px]">
        <img *ngIf="event.bannerUrl" class="w-full h-full object-cover" [src]="event.bannerUrl" alt="">
      </div>
      <div class="w-full flex flex-col items-center justify-center gap-1 relative">
        <h3 class="text-sm text-center mt-4">{{ event.title }}</h3>

        <div class="w-full flex flex-col items-start mt-4">
          <p class="font-semibold text-xxs text-gray-400">Tipo de evento</p>
          <p class="text-gray-600 text-xs">{{ EVENT_TYPE_VALUES[event.eventType] }}</p>

          <p class="font-semibold text-xxs text-gray-400 mt-2">Resumen</p>
          <p class="text-gray-600 text-xs lowercase first-letter:uppercase" nz-typography nzEllipsis
             [nzEllipsisRows]="3">{{ event.summary || '-' }}</p>

          <p class="font-semibold text-xxs text-gray-400 mt-2">Sector económico</p>
          <p class="text-gray-600 text-xs">{{ event.sectorName || '-' }}</p>
          
        </div>
      </div>
    </div>
  `,
  styles: [
    `
    .banner {
      @apply bg-gray-100;
    }
    `
  ],
  imports: [
    UploadModule,
    NgIf,
    NzAvatarModule,
    GetInitialLetterPipe,
    AddHTTPSPipe,
    DatePipe,
    NgForOf,
    NzTypographyModule,
    JsonPipe,
  ],
  standalone: true,
})
export class CommandPaletteEventComponent {
  @Input() event!: IEventBasicInformation;

  protected readonly PLATFORM_CONFIG = PLATFORM_CONFIG;
  protected readonly EVENT_TYPE_VALUES = EVENT_TYPE_VALUES;
}
