import {Component, ElementRef, OnDestroy, signal, ViewChild, WritableSignal} from '@angular/core';
import {ICommandPaletteGroup, ICommandPaletteGroupItem} from '../../interfaces';
import {fadeAnimation} from '@core/animations/fade.animation';
import {Select, Store} from '@ngxs/store';
import {CommandPaletteActions, CommandPaletteState} from '../../state/command-palette.state';
import {lastValueFrom, Observable} from 'rxjs';
import {cloneDeep} from 'lodash';
import {AuthState} from '@auth/state';
import Fuse, {FuseResult} from 'fuse.js'
import {IAnyObject} from '@shared/interfaces';
import {UtilityClass} from '@shared/utils/utility.class';

@Component({
    selector: 'wz-command-palette',
    templateUrl: './command-palette.component.html',
    styleUrls: ['./command-palette.component.scss'],
    animations: [fadeAnimation]
})
export class CommandPaletteComponent implements OnDestroy{

    $groups: WritableSignal<ICommandPaletteGroup[]> = signal<ICommandPaletteGroup[]>([]);
    $searchGroup: WritableSignal<ICommandPaletteGroup[]> = signal<ICommandPaletteGroup[]>([]);
    $groupsTab: WritableSignal<ICommandPaletteGroup[]> = signal<ICommandPaletteGroup[]>([]);

    @Select(CommandPaletteState.getVisibility) $isVisible!: Observable<boolean>;
    @Select(AuthState.userAuthenticated) $userAuthenticated!: Observable<boolean>;

    destroy: boolean = false;
    searchValue: string = '';
    timeout: any;

    timeoutId: any;
    groupSelected?: ICommandPaletteGroup;
    indexItem: number = 0;

    fuse?: Fuse<ICommandPaletteGroup>;
    fuseOptions: IAnyObject = {
        keys: ['title'],
        includeScore: true,
        threshold: 0.4
    };

    loading: boolean = false;
    @ViewChild('containerGroupItems') containerGroupItems?: ElementRef;

    constructor(private store: Store) {
        this.initGroups();
        this.registerKeyHandlerEvent();
    }

    initGroups(): void {
        this.$groups.set([
            {title: 'Organización', iconClass: 'las la-building', color: '#c719c7', items: [
                    {title: 'Crear Proveedor', route: '/suppliers/new', iconClass: 'las la-plus-circle'},
                    {title: 'Crear Comprador', route: '/buyers/new',  iconClass: 'las la-plus-circle'},
                ]},
            { title: 'Eventos', iconClass: 'las la-calendar', color: '#1ebbe6', items: [
                    {title: 'Crear Evento', route: '/events/new',  iconClass: 'las la-plus-circle'},
                    {title: 'Ver Eventos', route: '/events',  iconClass: 'las la-eye'},
                    { title: 'Ver Agenda', route: '/connections/calendar', iconClass: 'las la-calendar-day' },
                ]},
            { title: 'Conexiones', iconClass: 'las la-network-wired', color: '#52c41a', items: [
                    {title: 'Crear Conexión', route: '/connections/new',  iconClass: 'las la-plus-circle'},
                    {title: 'Ver Conexiones', route: '/connections/list',  iconClass: 'las la-eye'},
                ]},
            { title: 'Oportunidades', iconClass: 'las la-handshake', color: '#52c41a', items: [
                    {title: 'Crear Oportunidad', route: '/opportunities/new',  iconClass: 'las la-plus-circle'},
                    {title: 'Ver Oportunidades', route: '/opportunities',  iconClass: 'las la-eye'},
                ]},
        ]);
        this.$groupsTab.set([
            {title: 'Organización', iconClass: 'las la-building', color: '#c719c7', items: []},
            { title: 'Eventos', iconClass: 'las la-calendar', color: '#1ebbe6', items: []},
            { title: 'Conexiones', iconClass: 'las la-network-wired', color: '#52c41a', items: []},
            { title: 'Oportunidades', iconClass: 'las la-handshake', color: '#52c41a', items: []},
            { title: 'Productos', iconClass: 'las la-boxes', color: '#52c41a', items: []},
            { title: 'Servicios', iconClass: 'las la-hands-helping', color: '#52c41a', items: []},
        ]);
        this.fuse = new Fuse(this.$groupsTab(), this.fuseOptions);
    }

    hide(): void {
        this.store.dispatch(new CommandPaletteActions.Hide());
        this.searchValue = '';
        this.removeKeyword();
        this.$searchGroup.set([]);
    }

    search(): void {
        if(this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(async (): Promise<void> => {
            if (this.groupSelected) {
                this.loading = true;
                return;
            }

            if (this.searchValue === '') {
                this.$searchGroup.set(this.setTags(cloneDeep(this.$groups())));
                return;
            }
            const items: ICommandPaletteGroup[] = cloneDeep(this.$groups().filter((group: ICommandPaletteGroup) => {
                return group.items.filter((item: ICommandPaletteGroupItem) => {
                    return item.title.toLowerCase().includes(this.searchValue.toLowerCase());
                }).length > 0;
            }));
            this.$searchGroup.set(this.setTags(items));
       }, 200);
    }

    setTags(items: ICommandPaletteGroup[]) {
        let tagIndex: number = 0;
        items.forEach((group: ICommandPaletteGroup) => {
            group.items.forEach((item) => {
                if (tagIndex > 9) return;
                if (item.route || item.action) item.keyTag = `Ctrl + ${tagIndex++}`;
            });
        });
        return items;
    }

    setKeyword(event: Event): void {
        event.stopPropagation();
        event.preventDefault();
        if (this.groupSelected || !this.searchValue) return;
        const fuseValues: FuseResult<ICommandPaletteGroup>[] | undefined = this.fuse?.search(this.searchValue);
        if (!fuseValues || fuseValues.length === 0) {
            this.removeKeyword();
            return;
        }
        const item: ICommandPaletteGroup = fuseValues[0].item;
        this.loading = true;
        this.searchValue = '';
        this.groupSelected = item;
        this.$searchGroup.set([]);
    }

    removeKeyword(): void {
        this.groupSelected = undefined;
        this.loading = false;
    }

    registerKeyHandlerEvent() {
        document.addEventListener('keydown', (event): void => {
            const isActive: boolean = this.store.selectSnapshot(CommandPaletteState.getVisibility);
            if (isActive) {
                if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                    this.selectGroupItemDown(event.key === 'ArrowDown' ? 'DOWN' : 'UP');
                }
                if (event.key === 'Tab') {
                    this.setKeyword(event);
                    return;
                }
                if (event.key === 'Backspace' && this.groupSelected && this.searchValue === '') {
                    this.removeKeyword();
                    this.$searchGroup.set(this.setTags(cloneDeep(this.$groups())));
                    return;
                }
                if (event.key === 'Escape') {
                    this.hide();
                }
                if (event.key === 'Enter') {
                    this.onDoActionOnenter();
                    return;
                }
                if ( event.ctrlKey && ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key)) {
                    event.preventDefault();
                    this.onDoActionOnShortcut(event.key);
                    return;
                }
            }
            if (!event.ctrlKey && !event.shiftKey && event.key !== 'Escape') return;
            if (event.ctrlKey && event.shiftKey && (event.key.toLowerCase()) === 'p') {
               lastValueFrom(this.store.dispatch(new CommandPaletteActions.Show())).then(() => {
                   setTimeout(() => {
                   document.getElementById('search-input')?.focus();
                   }, 200);
               });
               return;
            }
        });
    }

    doAction(item: ICommandPaletteGroupItem, keyTag?: string): void {
        if (keyTag !== undefined) {
            if (item.keyTag !== `Ctrl + ${keyTag}`) return;
        }
        if (item.action) {
            item.action();
        }
        if (item.route) {
            window.location.replace(item.route); // forma rápida de recargar la página incluso si solo se cambia id
        }
        this.hide();
    }

    deleteKeyHandlerEvent(): void {
        document.removeEventListener('keydown', () => {});
    }

    selectGroupItemDown(direction: 'UP' | 'DOWN'): void {
        const items: ICommandPaletteGroupItem[] = this.$searchGroup().map((group: ICommandPaletteGroup) => group.items).flat();
        const index: number = items.findIndex((item: ICommandPaletteGroupItem) => item.selected);
        if (index === -1) {
            items[0].selected = true;
            this.indexItem = 0;
            return;
        }
        if (index === 0 && direction === 'UP') return;
        if (index >= items.length - 1 && direction === 'DOWN') return;
        items[index].selected = false;
        const newIndex: number = direction === 'UP' ? index - 1 : index + 1;
        items[newIndex].selected = true;
        if (this.groupSelected) this.indexItem = newIndex;
        if (this.containerGroupItems) {
            const element = this.containerGroupItems.nativeElement.getElementsByClassName('item-group')[newIndex];
            UtilityClass.outOfView(this.containerGroupItems.nativeElement, element, 0);
        }
    }

    onMarkAsSelected(item: ICommandPaletteGroupItem, index: number): void {
        if (!this.groupSelected) return;
        const timer: number = 1000;
        this.timeoutId = setTimeout((): void => {
            const items: ICommandPaletteGroupItem[] = this.$searchGroup().map((group: ICommandPaletteGroup) => group.items).flat();
            items.forEach(((_item: ICommandPaletteGroupItem): boolean => _item.selected = false));
            item.selected = true;
            this.indexItem = index;
        }, timer);
    }

    onMouseOut(): void {
        clearTimeout(this.timeoutId);
    }

    onDoActionOnenter(): void {
        const items: ICommandPaletteGroupItem[] = this.$searchGroup().map((group: ICommandPaletteGroup) => group.items).flat();
        const index: number = items.findIndex((item: ICommandPaletteGroupItem) => item.selected);
        if (index !== -1) {
            this.doAction(items[index], index.toString());
        }
    }

    onDoActionOnShortcut(eventKey: string): void {
        const index: number = parseInt(eventKey);
        const items: ICommandPaletteGroupItem[] = this.$searchGroup().map((group: ICommandPaletteGroup) => group.items).flat();
        this.doAction(items[index], index.toString());
    }

    onKeywordValuesChanged(groups: ICommandPaletteGroup[]): void {
        if (!this.groupSelected) return;
        this.$searchGroup.set(this.setTags(groups));
        this.indexItem = -1;
        this.loading = false;
    }

    ngOnDestroy() {
        this.destroy = true;
        this.deleteKeyHandlerEvent();
    }

}
