import {Component, Input} from '@angular/core';
import {UploadModule} from '@shared/components/ui-elements/upload/upload.module';
import {DatePipe, JsonPipe, NgForOf, NgIf} from '@angular/common';
import {IOrganizationProfile} from '@organization/interfaces';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {AddHTTPSPipe, GetInitialLetterPipe} from '@shared/pipes';
import {PLATFORM_CONFIG} from '@shared/config';
import {NzTypographyModule} from 'ng-zorro-antd/typography';

@Component({
  selector: 'command-palette-organization',
  template: `
    <div class="p-4 border-l border-gray-100 rounded w-[300px] h-full">
      <div class="banner w-full overflow-hidden rounded h-[120px] bg-gray-200"
        [style.background-image]="organization.bannerUrl ? 'url('+ organization.bannerUrl +')' : 'linear-gradient(45deg, #3cf9af 38%, #f85d63 2% 50%, #ffd87b 50% 82%, #119af7 20%)'"
      > <!--banner-->
        <!--<img class="w-full h-full object-cover" *ngIf="organization.bannerUrl" [src]="organization.bannerUrl" alt="">-->
      </div>
      <div class="w-full flex flex-col items-center justify-center gap-1 relative top-[-40px]">
        <nz-avatar class="!text-3xl min-w-[80px] uppercase" [nzSize]="80" [nzSrc]="organization.logoUrl" [nzText]="organization.tradeName | initialLetter"></nz-avatar>
        <h3 class="text-sm">{{ organization.tradeName }}</h3>
        
        <div class="w-full flex flex-col items-start mt-4">
          <p class="font-semibold text-xxs text-gray-400">Sector económico</p>
          <p class="text-gray-600 text-xs">{{ organization.sectorName || '-' }}</p>

          <p class="font-semibold text-xxs text-gray-400 mt-2">Descripción corta</p>
          <p class="text-gray-600 text-xs lowercase first-letter:uppercase" nz-typography nzEllipsis [nzEllipsisRows]="3">{{ organization.shortDescription || '-' }}</p>

          <p class="font-semibold text-xxs text-gray-400 mt-2">Portal web</p>
          <a class="text-xs" *ngIf="organization.webPortal" [href]="organization.webPortal | addHTTPS">{{ organization.webPortal }}</a>
          <p class="text-gray-600 text-xs" *ngIf="!organization.webPortal">-</p>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
    .banner {
      @apply bg-cover bg-center bg-no-repeat ;
    }
    `
  ],
  imports: [
    UploadModule,
    NgIf,
    NzAvatarModule,
    GetInitialLetterPipe,
    AddHTTPSPipe,
    DatePipe,
    NgForOf,
    NzTypographyModule,
    JsonPipe,
  ],
  standalone: true,
})
export class CommandPaletteOrganizationComponent {
  @Input() organization!: IOrganizationProfile;
  current = new Date();
  protected readonly PLATFORM_CONFIG = PLATFORM_CONFIG;
}
