@if ($userAuthenticated | async) {
    <div *ngIf="$isVisible | async" @fadeAnimation  class="z-[999999] absolute top-0 left-0 bg-white/50 w-screen h-screen p-5 md:p-20" (click)="this.hide()" >
        <div class="container mx-auto relative">
            <div class="shadow-2xl rounded-3xl bg-white md:mx-10 lg:mx-auto w-full md:w-5/6 lg:w-[42rem] lg:min-w-max overflow-hidden">
                <div class="flex flex-row items-center p-2" (click)="$event.preventDefault();$event.stopPropagation();">
                    <i class="text-gray-600 las la-search text-2xl mt-1 ml-2"></i>
                    <keyword-command-palette
                            [groupSelected]="groupSelected"
                            [searchValue]="searchValue"
                            (valuesChange)="onKeywordValuesChanged($event)"
                    >
                    </keyword-command-palette>
                    <input id="search-input" [(ngModel)]="this.searchValue"  (ngModelChange)="this.search()" placeholder="Buscar..." nz-input class="border-0 hover:border-0 focus:border-0 focus:ring-0 h-[40px] text-[20px]" />
                    <shortcut-tab-command-palette [groupsTab]="$groupsTab" [value]="searchValue"></shortcut-tab-command-palette>
                </div>
                <div class="w-full h-[1px] bg-gray-200"></div>
                <div class="container-items">
                  <div #containerGroupItems class="overflow-y-scroll max-h-[400px]">
                    @if (loading) {
                      <p @fadeAnimation class="p-6 text-sm text-gray-500 text-center">Cargando...</p>
                    } @else {
                      @for ($group of $searchGroup(); track $group.items) {
                        <div class="my-3" *ngIf="$group.items.length > 0">
                          <div class="flex gap-4">
                            <div class="w-full lg:max-w-2xl lg:w-[42rem]">
                              <h5 class="font-bold text-xs uppercase text-gray-500 p-2">{{  $group.title }}</h5>
                              @for ($itemGroup of $group.items; track $itemGroup.title) {
                                <div class="item-group p-2 px-4 transition-all flex flex-row items-center cursor-pointer hover:bg-gray-100 hover:font-bold justify-between" [class.bg-gray-100]="$itemGroup.selected" [class.font-bold]="$itemGroup.selected" (click)="this.doAction($itemGroup)" (mouseover)="onMarkAsSelected($itemGroup, $index)" (mouseout)="onMouseOut()">
                                  <div class="flex items-center" style="width: calc(100% - 100px)">
                                    <div class="flex flex-row mb-1">
                                      <i class="{{ $group.iconClass }} text-gray-600 text-base mt-1"></i>
                                      <i class="las la-long-arrow-alt-right text-base mt-1 text-pink-500"></i>
                                      <i class="{{ $itemGroup.iconClass }} text-gray-600 text-base mt-1 mr-2"></i>
                                    </div>
                                    <div class="relative truncate">
                                      <div class="truncate">{{ $itemGroup.title }}</div>
                                      <div class="truncate text-xxs text-gray-400">{{ $itemGroup.subtitle }}</div>
                                    </div>
                                  </div>
                                  <div *ngIf="$itemGroup.keyTag && $itemGroup.keyTag.length > 0" class="bg-pink-500 p-1 rounded text-white text-[8px] uppercase">{{ $itemGroup.keyTag }}</div>
                                </div>
                              }
                            </div>

                            <div *ngIf="groupSelected && indexItem !== -1 && $searchGroup()[0].items[indexItem] as item" class="container-preview sticky lg:!sticky lg:top-3 lg:!right-0 lg:!rounded-none border-l border-gray-100" [ngClass]="{'collapsed' : preview.collapsePreview}">
                              <command-palette-preview #preview [groupSelected]="groupSelected" [item]="item" (click)="$event.stopPropagation();$event.preventDefault();"></command-palette-preview>
                            </div>
                          </div>
                        </div>
                      }
                    }
                  </div>
                </div>
            </div>
        </div>
    </div>
}
