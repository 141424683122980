import {Pipe, PipeTransform, Signal, WritableSignal} from "@angular/core"
import {ICommandPaletteGroup, ICommandPaletteGroupItem} from '../interfaces';

@Pipe({
  name: 'getCommandPaletteItem',
  standalone: true,
})

export class GetCommandPaletteItemPipe implements PipeTransform {
  // borrar este pipe
  transform(groups: WritableSignal<ICommandPaletteGroup[]>): ICommandPaletteGroupItem | undefined {
    const items: ICommandPaletteGroupItem[] = groups().map((group: ICommandPaletteGroup) => group.items).flat();
    return undefined;
  }
}
