import {Store} from '@ngxs/store';
import {firstValueFrom} from 'rxjs';
import {SearchKeyword} from './search-keyword.class';
import {OrganizationActions, OrganizationState} from '@organization/state';
import {ISearchKeywordResultItem} from '../../components/keyword-command-palette/search-keyword-result-item.interface';
import {IOrganizationProfile, IOrganizationWithRelations} from '@organization/interfaces';
import {PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {OrganizationTypeEnum} from '@organization/enums';
import {ORGANIZATION_TYPE_VALUES} from '@shared/config';

export class SearchKeywordOrganization implements SearchKeyword {
  _store!: Store;
  constructor() {}

  setStore(store: Store) {
    this._store = store;
  }
  async search(value: string): Promise<void> {
    const filter: PrismaFilter<IOrganizationWithRelations> = {
      take: 10,
      where: {
        OR: [
          {tradeName: {contains: value, mode: 'insensitive'}},
          {legalName: {contains: value, mode: 'insensitive'}},
          {rfc: {contains: value, mode: 'insensitive'}},
          {
            SubSector: {
              OR: [
                {
                  Sector: {
                    name: {contains: value, mode: 'insensitive'}
                  },
                },
                {name: {contains: value, mode: 'insensitive'}}
              ]
            }
          },
          {
            GovernmentAgency: {
              GovernmentAgency: {
                OR: [
                  {name: {contains: value, mode: 'insensitive'}},
                  {shortName: {contains: value, mode: 'insensitive'}},
                ]
              }
            }
          }
        ]
      }
    }
    await firstValueFrom(this._store.dispatch(new OrganizationActions.GetProfiles(filter)));
  }

  async getValues(): Promise<ISearchKeywordResultItem[]> {
    const organizations: IOrganizationProfile[] | undefined = await firstValueFrom(this._store.selectOnce(OrganizationState.getOrganizationProfileList));
    return organizations.map((organization: IOrganizationProfile): ISearchKeywordResultItem => ({id: organization.id!, title: organization.tradeName, subtitle: ORGANIZATION_TYPE_VALUES[organization.organizationType!], route: `/organizations/${organization.id}`, metadata: {item: organization}}));
  }
}
