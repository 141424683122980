import {Component} from '@angular/core';
import {fadeAnimation} from '@core/animations/fade.animation';
import packageJson from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation]
})
export class AppComponent {
  constructor() {
    console.log(`Versión ${packageJson.version}`);
  }
}
