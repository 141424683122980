import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CommandPaletteComponent} from './components/command-palette/command-palette.component';
import {NzInputModule} from 'ng-zorro-antd/input';
import {FormsModule} from '@angular/forms';
import {KeywordCommandPaletteComponent} from './components/keyword-command-palette/keyword-command-palette.component';
import {
  CommandPaletteOrganizationComponent
} from './components/command-palette-organization/command-palette-organization.component';
import {GetCommandPaletteItemPipe} from './pipes/get-command-palette-item.pipe';
import {CommandPaletteEventComponent} from './components/command-palette-event/command-palette-event.component';
import {
  CommandPaletteOpportunityComponent
} from './components/command-palette-opportunity/command-palette-opportunity.component';
import {
  ShortcutTagCommandPaletteComponent
} from './components/shortcut-tag-command-palette/shortcut-tag-command-palette.component';
import {CommandPaletteProductComponent} from './components/command-palette-product/command-palette-product.component';
import {CommandPaletteServiceComponent} from './components/command-palette-service/command-palette-service.component';
import {
  CommandPaletteConnectionRequestComponent
} from './components/command-palette-connection-request/command-palette-connection-request.component';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {CommandPalettePreviewComponent} from './components/command-palette-preview/command-palette-preview.component';



@NgModule({
  declarations: [
      CommandPaletteComponent
  ],
  imports: [
    CommonModule,
    NzInputModule,
    FormsModule,
    KeywordCommandPaletteComponent,
    CommandPaletteOrganizationComponent,
    GetCommandPaletteItemPipe,
    CommandPaletteEventComponent,
    CommandPaletteOpportunityComponent,
    ShortcutTagCommandPaletteComponent,
    CommandPaletteProductComponent,
    CommandPaletteServiceComponent,
    CommandPaletteConnectionRequestComponent,
    NzToolTipModule,
    CommandPalettePreviewComponent,
  ],
  exports: [
      CommandPaletteComponent
  ]
})
export class CommandPaletteModule { }
