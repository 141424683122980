import {Component, Input} from '@angular/core';
import {UploadModule} from '@shared/components/ui-elements/upload/upload.module';
import {DatePipe, JsonPipe, NgForOf, NgIf} from '@angular/common';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {AddHTTPSPipe, GetInitialLetterPipe} from '@shared/pipes';
import {PLATFORM_CONFIG} from '@shared/config';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {IProductBasicInformation} from '@product/interfaces';

@Component({
  selector: 'command-palette-product',
  template: `
    <div class="p-4 border-l border-gray-100 rounded w-[300px] h-full">
      <div class="banner w-full overflow-hidden rounded h-[120px]">
        <img class="w-full h-full object-cover" *ngIf="product.organizationBannerUrl" [src]="product.organizationBannerUrl" alt="">
      </div>
      <div class="w-full flex flex-col items-center justify-center gap-1 relative top-[-40px]">
        <nz-avatar class="!text-3xl min-w-[80px] uppercase" [nzSize]="80" [nzSrc]="product.organizationLogoUrl" [nzText]="product.organizationTradeName | initialLetter"></nz-avatar>
        <h3 class="text-sm">{{ product.organizationTradeName }}</h3>
        
        <div class="w-full flex flex-col items-start mt-4">
          <p class="font-semibold text-xxs text-gray-400">Nombre del producto</p>
          <p class="text-gray-600 text-xs">{{ product.name || '-' }}</p>
          
          <p class="font-semibold text-xxs text-gray-400 mt-2">Categoría del producto</p>
          <p class="text-gray-600 text-xs">{{ product.categoryName || '-' }}</p>

          <p class="font-semibold text-xxs text-gray-400 mt-2">Capacidad</p>
          <p class="text-gray-600 text-xs" >{{ (product.capacity + ' ' + product.volumeUnitName) || '-' }}</p>
          
        </div>
      </div>
    </div>
  `,
  styles: [
    `
    .banner {
      background: linear-gradient(45deg, #3cf9af 38%, #f85d63 2% 50%, #ffd87b 50% 82%, #119af7 20%);
    }
    `
  ],
  imports: [
    UploadModule,
    NgIf,
    NzAvatarModule,
    GetInitialLetterPipe,
    AddHTTPSPipe,
    DatePipe,
    NgForOf,
    NzTypographyModule,
    JsonPipe,
  ],
  standalone: true,
})
export class CommandPaletteProductComponent {
  @Input() product!: IProductBasicInformation;
  protected readonly PLATFORM_CONFIG = PLATFORM_CONFIG;
}
