<nz-sider nzCollapsible [nzCollapsed]="isCollapsed()"
nzBreakpoint="md" [nzCollapsedWidth]="this.responsiveService.size() < 768 ? 0 : 64"
  (nzCollapsedChange)="isCollapsed.set($event)"
  class="bg-gray-50 block h-full fixed z-10 md:relative"
  [class.is-menu-collapsed]="isCollapsed()" [nzWidth]="'250px'" [nzTrigger]="this.responsiveService.size() < 768 ? iconMenu : null" [nzZeroTrigger]="iconMenu">

  <ng-template #iconMenu >
    <i   class="las text-2xl cursor-pointer" [class.la-chevron-circle-left]="!isCollapsed()" [class.la-chevron-circle-right]="isCollapsed()"></i>
  </ng-template>

  <div class="wrapper">
    <div class="header-logo relative h-10 mb-6 mt-6 w-full px-5 justify-center hidden md:flex">
      <img class="w-complete h-full object-contain w-[80%]" src="./assets/images/logo/concamin-horizontal-color.png" alt="">
      <img class="w-letter h-full object-contain" src="./assets/images/logo/concamin-icon-color.png" alt="">
    </div>

    <!-- avatar profile -->
    <div class="container-avatar-name px-5 hidden items-center justify-between w-full mb-6 md:flex" *ngIf="(this.userAuthenticated$ | async) as userAuthenticated">
      <div class="user-avatar-name">
        <nz-avatar [nzSize]="40" class="min-w-[40px] uppercase"
                   [nzSrc]="userAuthenticated.Media | firstUrlFromMedia"
                   [nzText]="userAuthenticated.name | initialLetter"
        ></nz-avatar>

        <div class="user-name-alias">
          <p class="name" nz-typography nzEllipsis>{{ userAuthenticated.name }} {{ userAuthenticated.lastName }}</p>
        </div>

        <div class="options-profile flex flex-col items-center justify-center cursor-pointer" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menuProfileOptions" [(nzVisible)]="this.nzVisible">
          <i class="las la-angle-up text-xs font-bold"></i>
          <i class="las la-angle-down text-xs font-bold"></i>
        </div>
      </div>
    </div>

    <!-- search -->
    <div class="px-5 mb-6 mt-5 md:mt-0 w-full flex justify-center">

      <button nz-button nzType="primary" *ngIf="isCollapsed()" (click)="onShowCommandPalette()">
        <i class="las la-search"></i>
      </button>

<!--      <button nz-button nzType="primary" class="w-full" *ngIf="!isCollapsed()" (click)="onShowCommandPalette()">-->
<!--        <i class="las la-search"></i>-->
<!--      </button>-->

      <nz-input-group [nzPrefix]="prefixTemplateUser" nzSize="large" *ngIf="!isCollapsed()">
        <input type="text" nz-input placeholder="Buscar" class="text-sm" [formControl]="this.searchCtrl" (focus)="onShowCommandPalette()"/>
      </nz-input-group>
      <ng-template #prefixTemplateUser><i class="las la-search mr-2"></i></ng-template>
    </div>

    <!--  items de menú  -->
    <div class="w-full h-full flex flex-col justify-between overflow-y-auto">
      <ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed()" class="bg-transparent border-none !w-full" *ngIf="menu.length > 0">
        <ng-container *ngFor="let menuItem of menu">
          <ng-container *ngIf="menuItem.subItems">
            <div nz-submenu [nzTitle]="menuItem.name" [nzIcon]="menuItem.iconType ?? 'home'">
              <ul>
                <li nz-menu-item *ngFor="let subItem of menuItem.subItems" nzMatchRouter
                    (click)="onChangeRoute()"
                    [routerLink]="subItem.url"
                    [routerLinkActiveOptions]="{exact: true}"
                    [routerLinkActive]="'selected'" [queryParams]="subItem.queryParams === undefined ? {} : subItem.queryParams"
                >
                  {{ subItem.name }}
                </li>
              </ul>
            </div>
          </ng-container>
          <ng-container *ngIf="!menuItem.subItems">
            <div nz-menu-item
                nzMatchRouter
                (click)="onChangeRoute()"
                [routerLink]="menuItem.url"
                [routerLinkActiveOptions]="{exact: true}"
                [routerLinkActive]="'selected'" [queryParams]="menuItem.queryParams === undefined ? {} : menuItem.queryParams"
            >
              <i [class]="menuItem.iconClass + ' menu-icon text-lg'"></i>
              <span class="item-menu">{{ menuItem.name }}</span>
            </div>
          </ng-container>
        </ng-container>
      </ul>

      <div class="wizerg-logo h-8 w-full flex justify-center px-5 my-5">
        <img class="min-w-full w-complete h-full object-contain" src="./assets/images/logo/tecnologico-de-monterrey.svg" alt="">
        <img class="min-w-full w-letter h-full object-contain hidden" src="./assets/images/logo/tecnologico-de-monterrey-icon.svg" alt="">
      </div>
    </div>
  </div>

  <!-- botón para colapsar el menú -->
  <div class="btn-collapse-menu" *ngIf="this.responsiveService.size() > 768"
       nz-tooltip
       [nzTooltipTitle]="isCollapsed() ? 'Mostrar menú' : 'Ocultar menú'"
       (click)="isCollapsed.set(!isCollapsed())"
  >
    <i class="las text-2xl cursor-pointer" [class.la-chevron-circle-left]="!isCollapsed()" [class.la-chevron-circle-right]="isCollapsed()"></i>
  </div>
</nz-sider>

<!-- dropdown menu account-information options -->
<nz-dropdown-menu #menuProfileOptions="nzDropdownMenu">
  <ul nz-menu class="p-2 bg-white">
    <li nz-menu-item class="flex gap-2 items-center" routerLink="/profile/information">
      <i class="las la-user-alt text-base"></i>
      Mi perfil
    </li>
    <li nz-menu-divider></li>
    <button nz-button nzType="primary" nzDanger class="flex gap-2 items-center mt-2" (click)="onLogout()">
      <i class="las la-sign-out-alt text-base"></i>
      Cerrar sesión
    </button>
  </ul>
</nz-dropdown-menu>
