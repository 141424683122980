import {Router, RouterModule} from '@angular/router';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {Component, OnDestroy, OnInit} from "@angular/core";
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {Loader} from '@shared/decorators';
import {firstValueFrom, Observable, takeWhile} from 'rxjs';
import {AuthActions, AuthState} from '@auth/state/auth.state';
import {Select, Store} from '@ngxs/store';
import {PlatformActions} from '@shared/state/platform.state';
import {IUser, IUserWithRelations} from '@user/interfaces';
import {CommonModule} from '@angular/common';
import {ResponsiveService} from '@shared/services';
import {PageHandler} from '@shared/interfaces';
import {FirstUrlFromMediaPipe, GetInitialLetterPipe} from '@shared/pipes';

@Component({
  selector: 'header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NzTypographyModule,
    NzDropDownModule,
    NzAvatarModule,
    NzButtonModule,
    FirstUrlFromMediaPipe,
    GetInitialLetterPipe,
  ],
})

export class HeaderMenuComponent implements OnInit, PageHandler, OnDestroy {
  @Select(AuthState.userAuthenticated) userAuthenticated$!: Observable<IUserWithRelations | null>;

  nzVisible!: boolean;
  activeObservables!: boolean;

  constructor(
    protected responsiveService: ResponsiveService,
    private router: Router,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.init();
    this.listeners();
  }

  init(): void {
    this.activeObservables = true;
    this.nzVisible = false;
  }

  listeners(): void {
    this.responsiveService.breakPoint$.pipe(takeWhile(() => this.activeObservables)).subscribe((): void => {
      this.nzVisible = false;
    });
  }

  @Loader()
  async onLogout(): Promise<void> {
    await firstValueFrom(this.store.dispatch(new AuthActions.Logout()));
    await firstValueFrom(this.store.dispatch(new PlatformActions.Reset()));
    await this.router.navigate(['/', 'auth', 'login']);
  }

  ngOnDestroy(): void {
    this.activeObservables = false;
  }
}
