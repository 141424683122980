import {Component, OnDestroy, OnInit, signal, WritableSignal} from '@angular/core';
import {NzMenuModule} from 'ng-zorro-antd/menu';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {CommonModule} from '@angular/common';
import {IMenuItem} from '@shared/components/ui-elements/menu/interfaces/menu-item.interface';
import {Router, RouterModule} from '@angular/router';
import {SkeletonsModule} from '@shared/components/skeletons/skeletons.module';
import {PageHandler} from '@shared/interfaces/handlers/startup-handler.interface';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {CatalogsEnum} from '@platform/catalog/enums/catalogs.enum';
import {ResponsiveService} from '@shared/services/responsive.service';
import {firstValueFrom, lastValueFrom, Observable, takeWhile} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {AuthActions, AuthState} from '@auth/state/auth.state';
import {IUserWithRelations} from '@user/interfaces';
import {Loader} from '@shared/decorators';
import {PlatformActions} from '@shared/state/platform.state';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {NzMessageService} from 'ng-zorro-antd/message';
import {FirstUrlFromMediaPipe, GetInitialLetterPipe} from '@shared/pipes';
import {ConfigSectionEnum} from '@platform/pages/enums/config-section.enum';
import {CommandPaletteActions} from '../../../../features/command-palette/state/command-palette.state';

@Component({
  selector: 'main-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    NzMenuModule,
    NzToolTipModule,
    NzIconModule,
    NzTypographyModule,
    NzInputModule,
    NzDropDownModule,
    NzButtonModule,
    NzLayoutModule,
    SkeletonsModule,
    NzAvatarModule,
    FirstUrlFromMediaPipe,
    GetInitialLetterPipe,
  ],
})
export class MenuComponent implements OnInit, PageHandler, OnDestroy {
  @Select(AuthState.userAuthenticated) userAuthenticated$!: Observable<IUserWithRelations | null>;

  searchCtrl!: FormControl<string>;
  isCollapsed: WritableSignal<boolean>;
  menu: IMenuItem[];
  loading!: boolean;
  activeObservables!: boolean;
  nzVisible!: boolean;


  constructor(
    private nzMessageService: NzMessageService,
    protected responsiveService: ResponsiveService,
    private router: Router,
    private store: Store,
  ) {
    this.isCollapsed = signal(false);
    this.menu = [];
    this.buildMenu();
  }

  ngOnInit(): void {
    this.init();
    this.listeners();
  }

  listeners(): void {
    this.responsiveService.breakPoint$.pipe(takeWhile(() => this.activeObservables)).subscribe((): void => {
      this.nzVisible = false;
    });
  }

  init(): void {
    this.nzVisible = false;
    this.loading = true;
    this.activeObservables = true;
    this.searchCtrl = new FormControl<string>('', {nonNullable: true});
  }

  /**
   * It is in charge of building the menu items
   */
  buildMenu(): void {
    this.menu = [
      {
        name: 'Inicio',
        visible: true,
        url: '/home',
        iconClass: 'las la-home',
        iconType: 'home'
      },
      {
        name: 'Perfil',
        visible: true,
        url: '/profile/information',
        iconClass: 'las la-address-card',
        iconType: 'user'
      },
      {
        name: 'Administradores',
        visible: true,
        iconClass: 'las la-users-cog',
        url: '/admin-users',
      },
      {
        name: 'Usuarios',
        visible: true,
        iconClass: 'las la-users',
        url: '/users',
      },
      {
        name: 'Empresas',
        url: '/organizations',
        iconClass: 'las la-sitemap',
        visible: true
      },
      {
        name: 'Productos y servicios',
        url: '/offers',
        iconClass: 'las la-archive',
        visible: true
      },
      {
        name: 'Oportunidades',
        url: '/opportunities',
        iconClass: 'las la-handshake',
        visible: true
      },
      {
        name: 'Conexiones',
        url: '/connections/list',
        iconClass: 'las la-network-wired',
        visible: true
      },
      {
        name: 'Agenda',
        url: '/connections/calendar',
        queryParams: { calendarDate: new Date().toISOString() },
        iconClass: 'las la-calendar-alt',
        visible: true
      },
      {
        name: 'Eventos',
        visible: true,
        url: '/events',
        iconClass: 'las la-calendar-check'
      },
      {
        name: 'Roles',
        visible: true,
        url: '/roles',
        iconClass: 'las la-user-tag'
      },
      {
        name: 'Configuración',
        visible: true,
        iconClass: 'las la-cog',
        iconType: 'appstore',
        subItems: [
          {
            name: 'Configuración',
            url: '/platform/configuration/general',
            queryParams: {configSection: ConfigSectionEnum.NOTICE_OF_PRIVACY},
            visible: true
          },
          {
            name: 'Servicios',
            url: '/platform/configuration/services',
            visible: true
          },
          {
            name: 'Catálogos',
            url: '/platform/configuration/catalogs',
            queryParams: {catalog: CatalogsEnum.PRODUCT_CATEGORIES},
            visible: true
          }
        ]
      }
    ];
  }


  @Loader()
  async onLogout(): Promise<void> {
    await firstValueFrom(this.store.dispatch(new AuthActions.Logout()));
    await firstValueFrom(this.store.dispatch(new PlatformActions.Reset()));
    await this.router.navigate(['/', 'auth', 'login']);
  }

  /**
   * Collapse menu
   */
  onChangeRoute(): void {
    if (this.responsiveService.size() < 768) { // Mobile
      return this.isCollapsed.set(true);
    }
  }

  async onShowCommandPalette(): Promise<void> {
    lastValueFrom(this.store.dispatch(new CommandPaletteActions.Show())).then(() => {
      setTimeout(() => {
        document.getElementById('search-input')?.focus();
      }, 200);
    });
  }

  ngOnDestroy(): void {
    this.activeObservables = false;
  }
}
