import {Component, Input} from '@angular/core';
import {
  CommandPaletteConnectionRequestComponent
} from '../command-palette-connection-request/command-palette-connection-request.component';
import {CommandPaletteEventComponent} from '../command-palette-event/command-palette-event.component';
import {CommandPaletteOpportunityComponent} from '../command-palette-opportunity/command-palette-opportunity.component';
import {
  CommandPaletteOrganizationComponent
} from '../command-palette-organization/command-palette-organization.component';
import {CommandPaletteProductComponent} from '../command-palette-product/command-palette-product.component';
import {CommandPaletteServiceComponent} from '../command-palette-service/command-palette-service.component';
import {NgClass, NgIf} from '@angular/common';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {ICommandPaletteGroup, ICommandPaletteGroupItem} from '../../interfaces';
import {fadeAnimation} from '@core/animations/fade.animation';

@Component({
  selector: 'command-palette-preview',
  template: `
    <ng-container *ngIf="groupSelected">
      <div class="collapse-preview lg:!hidden" (click)="$event.preventDefault(); $event.stopPropagation();collapsePreview = !collapsePreview;" [ngClass]="{'collapsed': collapsePreview}">
        <i *ngIf="!collapsePreview" class="las la-angle-right"></i>
        <i *ngIf="collapsePreview" class="las la-angle-left"></i>
<!--        <div class="flex items-center gap-2" *ngIf="showShortcut">-->
<!--          <span class="text-xxs text-gray-400">{{ collapsePreview ? 'Expandir' : 'Contraer' }}</span>-->
<!--          <span class="text-xxs text-gray-700 py-1 px-1.5 rounded bg-gray-100 font-semibold">TAB</span>-->
<!--        </div>-->
<!--        <div class="" (mouseover)="onShowMessage()" (mouseout)="onMouseOut()">-->
<!--          -->
<!--        </div>-->
      </div>
      <command-palette-organization *ngIf="groupSelected.title === 'Organización'" @fadeAnimation [organization]="item.metadata!.item!"></command-palette-organization>
      <command-palette-event *ngIf="groupSelected.title === 'Eventos'" @fadeAnimation [event]="item.metadata!.item!"></command-palette-event>
      <command-palette-opportunity *ngIf="groupSelected.title === 'Oportunidades'" @fadeAnimation [opportunity]="item.metadata!.item!"></command-palette-opportunity>
      <command-palette-product *ngIf="groupSelected.title === 'Productos'" @fadeAnimation [product]="item.metadata!.item!"></command-palette-product>
      <command-palette-service *ngIf="groupSelected.title === 'Servicios'" @fadeAnimation [service]="item.metadata!.item!"></command-palette-service>
      <command-palette-connection-request *ngIf="groupSelected.title === 'Conexiones'" @fadeAnimation [connection]="item.metadata!.item!"></command-palette-connection-request>
    </ng-container>
  `,
  styles: [
    `
      :host {
        @apply block h-full;
      }
      .collapse-preview {
        @apply w-[30px] h-[30px] bg-black text-white flex items-center justify-center gap-1 absolute z-10 cursor-pointer;
        left: -15px;
        border-radius: 2px;
        top: 6px;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        transition: width 200ms ease-in-out;
      }
      .collapse-preview.collapsed {
        width: 44px;
        left: -59px;
      }
    `
  ],
  animations: [fadeAnimation],
  imports: [
    CommandPaletteConnectionRequestComponent,
    CommandPaletteEventComponent,
    CommandPaletteOpportunityComponent,
    CommandPaletteOrganizationComponent,
    CommandPaletteProductComponent,
    CommandPaletteServiceComponent,
    NgIf,
    NzToolTipModule,
    NgClass,
  ],
  standalone: true,
})
export class CommandPalettePreviewComponent {
  @Input() groupSelected!: ICommandPaletteGroup;
  @Input() item: any;
  collapsePreview: boolean = false;
}
