import {HeaderMenuComponent} from './../../components/ui-elements/header-menu/header-menu.component';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {Component, OnInit} from "@angular/core";
import {RouterModule} from "@angular/router";
import {MenuComponent} from "@shared/components/ui-elements/menu/menu.component";
import {PageHandler} from '@shared/interfaces/handlers/startup-handler.interface';
import {Store} from '@ngxs/store';
import {firstValueFrom} from 'rxjs';
import {AuthActions} from '@auth/state/auth.state';

@Component({
  selector: 'dashboard-template',
  templateUrl: './dashboard.template.html',
  styleUrls: ['./dashboard.template.scss'],
  standalone: true,
  imports: [
    MenuComponent,
    RouterModule,
    NzDropDownModule,
    HeaderMenuComponent,
  ],
})
export class DashboardTemplate implements OnInit, PageHandler {
  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.init();
    this.request().then();
  }

  init(): void {}

  async request(): Promise<void> {
    await firstValueFrom(this.store.dispatch(new AuthActions.SetStorageUser()));
  }
}
