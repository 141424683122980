import {Component, Input} from '@angular/core';
import {UploadModule} from '@shared/components/ui-elements/upload/upload.module';
import {DatePipe, JsonPipe, NgForOf, NgIf} from '@angular/common';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {AddHTTPSPipe, GetInitialLetterPipe} from '@shared/pipes';
import {CONNECTION_TYPE_VALUES, PLATFORM_CONFIG, SERVICE_TURN_AROUND_TIME_VALUES} from '@shared/config';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {IServiceBasicInformation} from '../../../service/interfaces';
import {IConnectionRequestBasicInformation} from '@connection-request/interfaces';
import {ConnectionTypeEnum} from '@connection-request/enums';

@Component({
  selector: 'command-palette-connection-request',
  template: `
    <div class="p-4 border-l border-gray-100 rounded w-[300px] h-full">
      <div class="w-full flex flex-col items-center justify-center gap-1 relative">
        <h3 class="text-sm text-center">{{ connection.title }}</h3>

        <div class="w-full">
          <div class="flex items-center gap-2 mt-4">
            <nz-avatar class="!text-xl min-w-[40px] uppercase" [nzSize]="40"
                       [nzSrc]="connection.hostOrganizationLogoUrl"
                       [nzText]="connection.hostOrganizationTradeName | initialLetter"></nz-avatar>
            <div>
              <p class="text-sm text-gray-800">{{ connection.hostOrganizationTradeName }}</p>
              <p class="text-xxs text-gray-400">Organizador</p>
            </div>
          </div>

          <div class="flex items-center gap-2 mt-2">
            <nz-avatar class="!text-xl min-w-[40px] uppercase" [nzSize]="40"
                       [nzSrc]="connection.invitedOrganizationLogoUrl"
                       [nzText]="connection.invitedOrganizationTradeName | initialLetter"></nz-avatar>
            <div>
              <p class="text-sm text-gray-800">{{ connection.invitedOrganizationTradeName }}</p>
              <p class="text-xxs text-gray-400">Invitado</p>
            </div>
          </div>
        </div>

        <div class="w-full flex flex-col items-start mt-4">
          <p class="font-semibold text-xxs text-gray-400">Tipo de conexión</p>
          <p class="text-gray-600 text-xs">{{ CONNECTION_TYPE_VALUES[connection.connectionType] }}</p>

          <p class="font-semibold text-xxs text-gray-400 mt-2">Fecha aceptada</p>
          <p class="text-gray-600 text-xs">{{ connection.dateAccepted ? (connection.dateAccepted | date : PLATFORM_CONFIG.DATE.FORMAT) : '-' }}</p>

          <p class="font-semibold text-xxs text-gray-400 mt-2">{{ connection.connectionType === ConnectionTypeEnum.ONSITE ? 'Dirección' : connection.connectionType === ConnectionTypeEnum.ONLINE ? 'Url' : 'Nombre del evento' }}</p>
          <p class="text-gray-600 text-xs"
             *ngIf="connection.connectionType === ConnectionTypeEnum.ONSITE">{{ connection.address ?? '-' }}</p>
          <p class="text-gray-600 text-xs"
             *ngIf="connection.connectionType === ConnectionTypeEnum.ONLINE">{{ connection.meetUrl ?? '-' }}</p>
          <p class="text-gray-600 text-xs"
             *ngIf="connection.connectionType === ConnectionTypeEnum.EVENT">{{ connection.eventName ?? '-' }}</p>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
    .banner {
      background: linear-gradient(45deg, #3cf9af 38%, #f85d63 2% 50%, #ffd87b 50% 82%, #119af7 20%);
    }
    `
  ],
  imports: [
    UploadModule,
    NgIf,
    NzAvatarModule,
    GetInitialLetterPipe,
    AddHTTPSPipe,
    DatePipe,
    NgForOf,
    NzTypographyModule,
    JsonPipe,
  ],
  standalone: true,
})
export class CommandPaletteConnectionRequestComponent {
  @Input() connection!: IConnectionRequestBasicInformation;
  protected readonly PLATFORM_CONFIG = PLATFORM_CONFIG;
  protected readonly SERVICE_TURN_AROUND_TIME_VALUES = SERVICE_TURN_AROUND_TIME_VALUES;
  protected readonly CONNECTION_TYPE_VALUES = CONNECTION_TYPE_VALUES;
  protected readonly ConnectionTypeEnum = ConnectionTypeEnum;
}
