import {SearchKeyword} from './search-keyword.class';
import {ISearchKeywordResultItem} from '../../components/keyword-command-palette/search-keyword-result-item.interface';
import {Store} from '@ngxs/store';

export class SearchKeywordContext {
  searchKeyword?: SearchKeyword;
  _store: Store;
  constructor(store: Store) {
    this._store = store;
  }

  setKeyword(searchKeyword: SearchKeyword): void {
    this.searchKeyword = searchKeyword;
    this.searchKeyword.setStore(this._store);
  }

  async search(value: string): Promise<void> {
    await this.searchKeyword?.search(value);
  }

  async getValues(): Promise<ISearchKeywordResultItem[]> {
    return this.searchKeyword?.getValues();
  }
}
